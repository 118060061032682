/**
  Centralized api method

  Usage:
    new Fetch({url : URI.login}).get().then(({data, response, status_code}) => {

    }).catch((errors, message, response, status_code) => {

    });

  Created by:
    [ 2019-11-29 ] Sue

  Updated by:
    [ 2019-12-02 ] Sue > add more options to support get(), create(), read(), patch(), delete()
    [ 2020-01-29 ] David Parlevliet > Fixed bugs so data and errors get returned as expected
*/
import axios from "axios";
import React from "react";

const CancelToken = axios.CancelToken;
const cancelTokens = {};

export class Fetch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: props.url,
      headers: props.headers,
      forum: props.forum,
      conversation: props.conversation
    };
  }

  getOption = (method, url, data, params) => {
    let { headers } = this.state;
    let support_uuid = window.localStorage.getItem("support_uuid");
    if (!headers) {
      headers = {};
    }
    headers["X-URI"] = window.location.href;
    return {
      headers: headers,
      ...{ data: data },
      ...{ params: params },
      url: url,
      method: method,
      cancelToken: cancelTokens[url].token,
      withCredentials: true,
      xsrfCookieName: "csrftoken",
      xsrfHeaderName: "X-CSRFToken"
    };
  };

  /**
   * Success handle function for Get
   * @param data{object} return data
   */
  onGetSuccess = data => {
    return {
      data: data.data.data,
      response: data.data,
      status_code: data.status
    };
  };

  /**
   * Success handle function for Create
   * @param data{object} return data
   */
  onCreateSuccess = data => {
    return {
      data: data.data.data,
      response: data.data,
      status_code: data.status
    };
  };

  /**
   * Success handle function for Read
   * @param data{object} return data
   */
  onReadSuccess = data => {
    return {
      data: data.data.data,
      response: data.data,
      status_code: data.status
    };
  };

  /**
   * Success handle function for Patch
   * @param data{object} return data
   */
  onPatchSuccess = data => {
    return {
      data: data.data.data,
      response: data.data,
      status_code: data.status
    };
  };

  /**
   * Success handle function for Delete
   * @param data{object} return data
   */
  onDeleteSuccess = data => {
    return {
      data: data.data.data,
      response: data.data,
      status_code: data.status
    };
  };

  /**
   * Error handle function
   * @param error{object} error information
   */
  onError = error => {
    console.error("Request Failed:", error);

    return Promise.reject(error.response || error.message);
  };

  checkCancel(url) {
    if (cancelTokens[url]) {
      cancelTokens[url].cancel("New pending request");
    }
    cancelTokens[url] = CancelToken.source();
  }

  getURL(id) {
    let { url, forum, conversation } = this.state;
    if (id) {
      url += "/" + id;
    }
    if (forum && forum.slug) {
      let formmer = "/" + forum.slug;
      if (conversation && conversation.id) {
        formmer += "/" + conversation.id;
      }
      url = formmer + url;
    }
    if (url.slice(-1) != "/") {
      url = url + "/";
    }
    return url;
  }

  /**
   * Get Method for getting certain data
   * @param id{string} parameters from request
   */
  $get = (id, params) => {
    let url = this.getURL(id);
    this.checkCancel(url);

    return axios
      .request(this.getOption("get", url, null, params))
      .then(data => {
        return this.onGetSuccess(data);
      })
      .catch(this.onError);
  };

  /**
   * Create Method for creating a data record
   * @param data{object} parameters from request
   */
  $create = data => {
    let url = this.getURL();
    this.checkCancel(url);

    return axios
      .request(this.getOption("post", url, data))
      .then(data => {
        return this.onCreateSuccess(data);
      })
      .catch(this.onError);
  };

  /**
   * Alias of $create
   */
  $post = data => {
    return this.$create(data);
  };

  /**
   * Read Method for getting data list
   */
  $read = (id, params) => {
    let url = this.getURL(id);

    this.checkCancel(url);

    return axios
      .request(this.getOption("get", url, null, params))
      .then(data => {
        return this.onReadSuccess(data);
      })
      .catch(this.onError);
  };

  /**
   * Patch Method for updating a data record
   * @param data{object} parameters from request
   */
  $patch = data => {
    let url = this.getURL();
    this.checkCancel(url);

    return axios
      .request(this.getOption("patch", url, data))
      .then(data => {
        return this.onPatchSuccess(data);
      })
      .catch(this.onError);
  };

  /**
   * Delete Method for deleting certain data
   * @param id{string} parameters from request
   */
  $delete = (id, params) => {
    let url = this.getURL(id);
    this.checkCancel(url);

    return axios
      .request(this.getOption("delete", url, null, params))
      .then(data => {
        return this.onDeleteSuccess(data);
      })
      .catch(this.onError);
  };
}

export default Fetch;
