import React, { useState, useEffect } from "react";
import Loading from "../../../../app/common/components/Loading";

export default function Complete({ }) {

  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    console.log(window.opener);
    if (window.opener && window.opener.oAuth2Callback) {
      window.opener.oAuth2Callback();
    }
  }, [ ]);

  return (
    <Loading loading={ loading } />
  );
};