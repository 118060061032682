/**
 *  The App component
 *
 *  Usage:
 *    NA
 *
 *  Created By:
 *    [ 2020-02-22 ] David Parlevliet
 */

import React, { useEffect, useState } from "react";
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import 'antd/dist/antd.css';

import { Spin } from 'antd';
import { LandingPage } from './app/pages/Login';
import { Complete as SSOComplete } from './app/pages/SSO';
import { Unauthorized as SSOUnauthorized } from './app/pages/SSO';
import Loading from "./app/common/components/Loading";
import Fetch from "./app/common/utils/fetch.jsx";



export default function App() {

  const [ loading, setLoading ] = useState(true);
  const [ client, setClient ] = useState(null);

  useEffect(() => {
    setLoading(true);
    new Fetch({ url: '/gateway/api/platform/' })
      .$read()
      .then(({ data }) => {
        setClient(data.client)
      })
      // error
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Loading loading={ loading }>
      {
        client &&
        <Router>
          <Switch>
            <Route exact path="/complete" render={({ match }) => <SSOComplete id={ match.params.id } />}></Route>
            <Route exact path="/unauthorized"><SSOUnauthorized /></Route>
            <Route exact path="/">
              <LandingPage client={ client } />
            </Route>
          </Switch>
        </Router>
      }
      {
        !client &&
        <div style={{ textAlign: 'center', padding: '50px' }}>
          <h1>Unable to find your gateway route</h1>
        </div>
      }
    </Loading>
  );
}
