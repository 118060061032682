import React, { useState, useEffect } from "react";
import { Spin } from 'antd';

export default function Loading({ loading, children }) {

  return (
    <>
      {
        loading &&
        <div style={{ padding: '50px', textAlign: 'center' }}>
          <Spin />
        </div>
      }
      { !loading && children }
    </>
  );
};