
import React, { useState, useEffect } from "react";
import { Row, Col } from 'antd';
import { Card } from 'antd';
import { Form, Input, Button, Checkbox } from 'antd';
import {
  UserOutlined,
  LockOutlined,
  TwitterOutlined,
  GoogleOutlined,
  WindowsOutlined,
  LinkedinOutlined,
  FacebookOutlined,
  LoginOutlined
} from '@ant-design/icons';

import EqLogoImage from '../../../../app/common/images/eq-logo.png';

import Fetch from "../../../../app/common/utils/fetch.jsx";

export default function LandingPage({ client }) {

  const [ loginType, setLoginType ] = useState(null);
  const [ loginForm, setLoginForm ] = useState({});

  const onValuesChange = (newValues, allValues) => {
    console.log('Received values of form: ', newValues);
    console.log('Received values of form: ', allValues);
    setLoginForm(allValues);
  };

  const openOAuthWindow = (url) => {
    const oWindow = window.open(url + '?platform=' + client.id, "Ratting", "width=600,height=500,left=150,top=200,toolbar=0,status=0");
    window.oAuth2Callback = () => {
      oWindow.close();
      window.location.href = '/';
    };
  };

  const openPlaetosAuthorityWindow = () => {
    window.location.href = '/gateway/api/plaetos/login/' + client.plaetos_authority_public_token + '/';
  };

  const formSubmit = () => {
    new Fetch({ url: '/gateway/api/users/login/' })
      .$post(loginForm)
      .then(({ data }) => {
        window.location.href = '/';
      })
      // error
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
      });
  };

  return (
    <Row style={{ margin: '50px'  }}>
      <Col span={18} style={{ backgroundColor: '#0d0852', height: 'calc(100vh - 100px)', textAlign: 'center' }}>
        <img src={ EqLogoImage } style={{ margin: 'auto', marginTop: 'calc(50vh - 100px)' }} />
      </Col>
      <Col span={6} style={{ height: 'calc(100vh - 100px)' }}>
        <Card style={{ width: '100%', height: '100%', overflow: 'auto' }}>
          {
            !loginType &&
            client &&
            client.direct_login_enabled &&
            <Button type="primary" style={{ width: '100%', backgroundColor: '#f89b3e', borderColor: '#f89b3e' }} onClick={ () => setLoginType('direct') } icon={<UserOutlined style={{ position: 'relative', top: '-3px' }} />}>
              Login with a username and password
            </Button>
          }
          {
            loginType == 'direct' &&
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onValuesChange={onValuesChange}
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please input your E-Mail!' }]}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Enter your e-mail" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Enter your password"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  onClick={ formSubmit }
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: '100%' }}
                >
                  Log in
                </Button>
              </Form.Item>
              <a onClick={ () => setLoginType(null) }>&lt; Back</a>
            </Form>
          }
          {
            loginType != 'direct' &&
            client &&
            client.plaetos_authority_enabled &&
            <Button onClick={ () => openPlaetosAuthorityWindow() } type="primary" style={{ width: '100%', marginTop: '10px', backgroundColor: '#3b1052', borderColor: '#3b1052' }} icon={<LoginOutlined style={{ position: 'relative', top: '-3px' }} />}>
              Login via Plaetos Voice
            </Button>
          }
          {
            loginType != 'direct' &&
            client &&
            client.sso_enabled &&
            <>
              {
                client.saml_enabled &&
                <Button onClick={ () => openOAuthWindow('/sso/1/saml2/login/') } type="primary" style={{ width: '100%', marginTop: '10px' }} icon={<LoginOutlined style={{ position: 'relative', top: '-3px' }} />}>
                  Login via Company Login
                </Button>
              }
              {
                client.adfs_enabled &&
                <Button onClick={ () => openOAuthWindow('/sso/1/adfs-wsfed/login/') } type="primary" style={{ width: '100%', marginTop: '10px' }} icon={<LoginOutlined style={{ position: 'relative', top: '-3px' }} />}>
                  Login via Company Login
                </Button>
              }
              {
                client.google_enabled &&
                <Button onClick={ () => openOAuthWindow('/sso/1/oauth2/google/login/') } type="primary" style={{ width: '100%', marginTop: '10px', backgroundColor: '#df3526', borderColor: '#df3526'  }} icon={<GoogleOutlined style={{ position: 'relative', top: '-3px' }} />}>
                  Login via Google
                </Button>
              }
              {
                client.facebook_enabled &&
                <Button onClick={ () => openOAuthWindow('/sso/1/oauth2/facebook/login/') } type="primary" style={{ width: '100%', marginTop: '10px' }} icon={<FacebookOutlined style={{ position: 'relative', top: '-3px' }} />}>
                  Login via Facebook
                </Button>
              }
              {
                client.linkedin_enabled &&
                <Button onClick={ () => openOAuthWindow('/sso/1/oauth2/linkedin/login/') } type="primary" style={{ width: '100%', marginTop: '10px' }} icon={<LinkedinOutlined style={{ position: 'relative', top: '-3px' }} />}>
                  Login via LinkedIn
                </Button>
              }
              {
                client.twitter_enabled &&
                <Button onClick={ () => openOAuthWindow('/sso/1/oauth2/twitter/login/') } type="primary" style={{ width: '100%', marginTop: '10px' }} icon={<TwitterOutlined style={{ position: 'relative', top: '-3px' }} />}>
                  Login via Twitter
                </Button>
              }
              {
                client.azuread_enabled &&
                <Button onClick={ () => openOAuthWindow('/sso/1/open-id/azure/login/') } type="primary" style={{ width: '100%', marginTop: '10px' }} icon={<WindowsOutlined style={{ position: 'relative', top: '-3px' }} />}>
                  Login via Azure
                </Button>
              }
            </>
          }
        </Card>
      </Col>
    </Row>
  );
};