import React, { useState, useEffect } from "react";
import { Typography, Space } from 'antd';
import Loading from "../../../../app/common/components/Loading";

const { Text, Title } = Typography;

export default function Unauthorized({ }) {

  return (
    <Loading loading={ false }>
      <Title style={{ textAlign: 'center', padding: '50px', color: 'red' }}>
        Unauthorized
      </Title>
      <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
        You have not been invited. Please contact your administrator.
      </div>
    </Loading>
  );
};